import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image/imeg.jpg'

function ourteam() {
  return (
   <>
      <Navbar/>

      <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom ">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
                
               
                <div id="content" class="site-content container container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-18 tie-col-xs-20" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                       <center><h1 class="post-title entry-title ">Our Team</h1></center> 
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <div class="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-2 wp-block-columns-is-layout-flex">
                                        <div class="wp-block-column is-layout-flow wp-block-column-is-layout-flow mt-4">
                                            <figure class="wp-block-image size-large"><img fetchpriority="high" decoding="async" width="682" height="1024" src={image} alt="" class="wp-image-1355" />
                                                <figcaption class="wp-element-caption">Rana Salman Liaqat – Barrister, Solicitor &#038; Notary Public</figcaption>
                                            </figure>
                                        </div>
                                        <div class="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                                            <p><br/>Welcome to Integrity Law Professional Corporation, where precision advocacy meets exceptional legal representation.</p>
                                            <p>At Integrity Law, we pride ourselves on our ability to navigate complex legal landscapes with precision and dedication. Led by Rana Salman Liaqat , a legal professional being a candidate for
                                                a Ph.D. in Social Science (Law) and significant exposure in Criminal Litigation, Immigration Law, and International Commercial Law – our firm is committed to providing top-tier advocacy and representation
                                                to our clients.</p>
                                            <p>In the realm of Criminal Litigation, we excel in diligent case management, strategic trial preparation, and skilled courtroom advocacy. Our team is adept at crafting compelling legal arguments, conducting thorough
                                                research, and leveraging our experience to achieve favorable outcomes for our clients facing a range of criminal charges.</p>
                                            <p>In Immigration Law, we understand the nuances and complexities of immigration processes and regulations. From visa applications to deportation defense, we provide comprehensive legal support to individuals and
                                                businesses navigating immigration matters. Our advocacy extends to asylum claims, family reunification, and employment-based immigration, ensuring our clients’ rights and interests are protected.</p>
                                            <p>In International Commercial Law and Arbitration, we bring extensive experience in handling complex commercial disputes and cross-border transactions. Our proficiency includes contract negotiations, dispute resolution,
                                                and arbitration proceedings. We are skilled at navigating international legal frameworks, crafting effective legal strategies, and advocating for our client’s interests in commercial disputes.</p>
                                            <p>At Integrity Law, precision advocacy is not just a slogan – it’s our commitment to excellence, continuous learning, and making meaningful contributions to the legal profession. We leverage our skills, knowledge,
                                                and experience to provide tailored legal solutions and achieve favorable outcomes for our clients.</p>
                                            <p>Thank you for choosing Integrity Law Professional Corporation. We look forward to serving you with dedication, integrity, and precision advocacy.</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                           
                        </div>
                       
                </div>
            </div>
          
    </div>
    </div>
   

   </div>
   <Footer/>
    
    
</body>


   </>
  )
}

export default ourteam