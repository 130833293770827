import React from 'react'
import "./Home.css"
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
function Home() {
  return (
    <div>
    <Navbar/>
      <body id="tie-body" class="home page-template-default page page-id-1017 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 has-builder hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay"> 
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
              
                <div id="tiepost-1017-section-2413" class="section-wrapper container normal-width has-background">
                    <div class="section-item is-first-section full-width" style={{ backgroundColor: '#ffffff', backgroundSize: 'cover' }}>
                        <div class="container-normal">
                            <div class="tie-row main-content-row">
                                <div class="main-content tie-col-md-12">
                                    <div id="tie-block_3024" class="mag-box block-custom-content">
                                        <div class="container-wrapper">
                                            <div class="mag-box-container clearfix">
                                                <div class="entry clearfix">
                                               
                                                
                                                <p>&nbsp;</p>
                                                    <p><img fetchpriority="high"  decoding="async" class="wp-image-1024 aligncenter  " style={{backgroundColor:"#1f2437"}}  src="https://ik.imagekit.io/cy8phtesy/integrity_logo-removebg-preview.png?updatedAt=1715955437178" alt="" width="512" height="300" /></p>
                                                    <p>&nbsp;</p>
                                               
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                
                                                    <p>Welcome to Integrity Law Professional Corporation, a premier law firm located in Canada, specializing in Criminal and Immigration Law. Our dedicated team provides expert legal representation, ensuring
                                                        our clients receive personalized attention and solutions tailored to their unique situations.</p>
                                                    <p>Understanding the challenges you face, we are committed to navigating the complexities of the legal system on your behalf. With integrity, empathy, and a relentless pursuit of justice, we strive to secure
                                                        the best possible outcomes for our clients.</p>
                                                    <p>Discover how Integrity Law can support you through your legal journey. Contact us today for a consultation, and let us be your advocate in protecting your rights and future.</p>
                                                    <div id="highlighter--hover-tools"
                                                        style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tiepost-1017-section-143" class="section-wrapper container normal-width without-background">
                    <div class="section-item full-width">
                        <div class="container-normal">
                            <div class="tie-row main-content-row">
                                <div class="main-content tie-col-md-12">
                                    <div id="tie-block_2576" class="mag-box block-custom-content">
                                        <div class="container-wrapper">
                                            <div class="mag-box-container clearfix">
                                                <div class="entry clearfix">
                                                    <h1 class="wp-block-heading has-text-align-center has-custom-gold-color has-text-color has-link-color wp-elements-83c40258e8e5692bb9abf14364def6c4" style={{ textAlign: 'center' }}><strong>Our Practice Areas</strong></h1>
                                                    <p>&nbsp;</p>
                                                    <h1 class="wp-block-heading has-text-align-center has-custom-gold-color has-text-color has-link-color wp-elements-83c40258e8e5692bb9abf14364def6c4" style={{ textAlign: 'center' }}>Criminal Law &amp; Regulatory</h1>
                                                    <p>&nbsp;</p>
                                                    <div class="one_fourth tie-columns">
                                                        <div class=" tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Impairedd" class="ms-2">Impaired Driving</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Domesticviolence" class="ms-2">Domestic Violence</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Drugchargies" class="ms-2">Drug Charges</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_fourth tie-columns">
                                                        <div class=" tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/BailHearings" class="ms-2">Bail Hearings</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Projectcases" class="ms-2">Project Cases</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Vehicleoffenses" class="ms-2">Vehicle Offences</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_fourth tie-columns">
                                                        <div class=" tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Duidefence" class="ms-2">DUI Defence</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Fraud" class="ms-2">Fraud</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Whitecollarcrime" class="ms-2">White Collar Crime</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_fourth tie-columns last">
                                                        <div class=" tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Firearms" class="ms-2">Firearms and Weapons</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Sexualassault" class="ms-2">Sexual Assault</a>
</li>

                                                            <li class="d-flex list-unstyled">
                                                                <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
                                                                <a href="/failure" class="ms-2">Failure to Provide Breath Sample</a>
                                                            </li>


                                                            </ul>
                                                        </div>

                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <p>&nbsp;</p>
                                                    <h1 class="wp-block-heading has-text-align-center has-custom-gold-color has-text-color has-link-color wp-elements-1a8a3a9f6cd0f511b9019855614410a6" style={{ textAlign: 'center' }}>Immigration Law</h1>
                                                    <p>&nbsp;</p>
                                                    

                                                  
                                                    <div class="one_third tie-columns ">
                                                        <h3  class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666 " style={{ textAlign: 'left',marginLeft:"20px"}}>Permanent Residence</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Familysponsership" class="ms-2">Family Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Spousalsponsorship" class="ms-2">Spousal Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Commonlawps" class="ms-2">Common Law Partner Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Dependantsponsorships" class="ms-2">Dependent Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Parentandgrandparentspo" class="ms-2">Parent and Grandparent Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Expressentry" class="ms-2">Express Entry</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Canadianexperienceclass" class="ms-2">Canadian Experience Class</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Federalskilledworkerp" class="ms-2">Federal Skilled Worker Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Federalskilledtradeprogram" class="ms-2">Federal Skilled Trade Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/provincialNprogram" class="ms-2">Provincial Nominee Programs</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Ontariolmmigrant" class="ms-2">Ontario Immigrant Nominee Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Startupvisa" class="ms-2">Start-Up Visa Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Selfemployedpersons" class="ms-2">Self Employed Persons</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Homechildcare" class="ms-2">Home Child Care Provider Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Homesupportworkerpilot" class="ms-2">Home Support Worker Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Agrifoodpilot" class="ms-2">Agri-Food Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Ruralandnorthern" class="ms-2">Rural and Northern Immigration Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Atlantic" class="ms-2">Atlantic Immigration Program</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns">
                                                        <h3 class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666" style={{ textAlign: 'left',marginLeft:"20px" }}>Temporary Residence</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Postg" class="ms-2">Post-Graduate Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LabourM" class="ms-2">Labour Market Impact Assessment (LMIA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LmiaW" class="ms-2">LMIA Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/TemporaryF" class="ms-2">Temporary Foreign Worker Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/GlobalS" class="ms-2">Global Skills Strategy</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/InterMM" class="ms-2">International Mobility Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/IntraC" class="ms-2">Intra Company Transfers</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LmiaEE" class="ms-2">LMIA Exempt Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/FreeTrad" class="ms-2">Free Trade Agreements</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CanadaU" class="ms-2">Canada–United States–Mexico Agreement (CUSMA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/GernalAgrement" class="ms-2">General Agreement on Trade in Services (GATS)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CanadaEuropean" class="ms-2">Canada-European Union Comprehensive Economic and Trade Agreement (CETA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/InternationalE" class="ms-2">International Experience Canada</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/BussniessV" class="ms-2">Business Visitor Visa</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/StudyP" class="ms-2">Study Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Temporary" class="ms-2">Temporary Resident Visas</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/ParentG" class="ms-2">Parent &amp; Grandparent Super Visas</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns last">
                                                        <h3 class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666" style={{ textAlign: 'left',marginLeft:"20px" }}>Appeals &amp; Removals</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Recon" class="ms-2">Reconsideration Requests</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Reapp" class="ms-2">Reapplications</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Author" class="ms-2">Authorization to Return</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Removal" class="ms-2">Removal &amp; Deportation</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Detention" class="ms-2">Detention Reviews</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Admiss" class="ms-2">Admissibility Hearings</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CriminalR" class="ms-2">Criminal Rehabilitation</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Judical" class="ms-2">Judicial Review</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Immigration" class="ms-2">Immigration Appeal Division</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/ImmigrationD" class="ms-2">Immigration Division</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Criminalin" class="ms-2">Criminal Inadmissibility</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Preremoval" class="ms-2">Pre-removal Risk Assessment</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Port" class="ms-2">Port of Entry Issues</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/TemporaryResi" class="ms-2">Temporary Resident Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Humaniarian" class="ms-2">Humanitarian &amp; Compassionate Grounds</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                   
                                                    <p>&nbsp;</p>
                                                    <h1 class="wp-block-heading has-text-align-center has-custom-gold-color has-text-color has-link-color wp-elements-83c40258e8e5692bb9abf14364def6c4" style={{ textAlign: 'center' }}>Notary Services</h1>
                                                    <p>&nbsp;</p>
                                                    <div class="one_third tie-columns">
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Oaths and Affidavits</a>
</li>

<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Service Ontario and Ministry of Transportation Forms</a>
</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns">
                                                        <div class=" tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Statutory Declarations</a>
</li>

<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Certified True Copies of Digital Documents</a>
</li>

<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Immigration, Refugee and Citizenship Canada (IRCC) Forms</a>
</li> 
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns last">
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Notarization of Original Documents</a>
</li>

<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Nor">Licensing and Professional Accreditation Forms</a>
</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tiepost-1017-section-2122" class="section-wrapper container normal-width without-background">
                    <div class="section-item full-width">
                        <div class="container-normal">
                            <div class="tie-row main-content-row">
                                <div class="main-content tie-col-md-12">
                                    <div id="tie-block_239" class="mag-box block-custom-content">
                                        <div class="container-wrapper">
                                            <div class="mag-box-container clearfix">
                                                <div class="entry clearfix">
                                                    <h1 class="wp-block-heading has-text-align-center is-style-default has-custom-gold-color has-text-color has-link-color wp-elements-d91a892d29c95f183737a909e81117d4" style={{ textAlign: 'center' }}><strong>Our Professional Affiliations</strong></h1>
                                                    <p>&nbsp;</p>
                                                    <p style={{ textAlign: 'center' }}>Integrity Law is honored to be part of respected lawyer associations, underscoring our commitment to excellence and ethical legal representation.</p>
                                                    <div class="one_fourth tie-columns"><img decoding="async" class="alignnone size-full wp-image-1026" src="wp-content/uploads/2024/04/1.png" alt="" width="488" height="488" /> </div>
                                                    <div class="one_fourth tie-columns"><img decoding="async" class="alignnone size-full wp-image-1028" src="wp-content/uploads/2024/04/3.png" alt="" width="488" height="488" /> </div>
                                                    <div class="one_fourth tie-columns"><img decoding="async" class="alignnone size-full wp-image-1027" src="wp-content/uploads/2024/04/2-1.png" alt="" width="488" height="488" /> </div>
                                                    <div class="one_fourth tie-columns last"><img decoding="async" class="alignnone size-full wp-image-1029" src="wp-content/uploads/2024/04/4-1.png" alt="" width="488" height="488" /> </div>
                                                    <div class="clearfix"></div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tie-block_3085" class="mag-box block-custom-content">
                                        <div class="container-wrapper">
                                            <div class="mag-box-container clearfix">
                                                <div class="entry clearfix">
                                                    <div class="box note aligncenter">
                                                        <div class="box-inner-block"> <span class="fa tie-shortcode-boxicon"></span>The information provided is for informational purposes only and should not be construed as legal advice. Applicants are advised to consult Lex Defensio
                                                            directly for accurate and up-to-date information on immigration procedures and eligibility criteria. </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                    <div id="highlighter--hover-tools" style={{ display: 'none' }}>
                                                        <div id="highlighter--hover-tools--container">
                                                            <div class="highlighter--icon highlighter--icon-copy" title="Copy"></div>
                                                            <div class="highlighter--icon highlighter--icon-change-color" title="Change Color"></div>
                                                            <div class="highlighter--icon highlighter--icon-delete" title="Delete"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
        </div>
    </div>
  
    <Footer/>
    
    

</body>
    </div> 
  )
}

export default Home
