import Footer from "../Footer";
import Navbar from "../Navbar.js/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ImmigrationPage=()=>{
    return(
        <>
<Navbar/>
<div class="clearfix"></div>
                                                    <p>&nbsp;</p>
                                                    <h1 class="wp-block-heading has-text-align-center has-custom-gold-color has-text-color has-link-color wp-elements-1a8a3a9f6cd0f511b9019855614410a6" style={{ textAlign: 'center' }}>Immigration Law</h1>
                                                    <p>&nbsp;</p>
                                                    

                                                  
                                                    <div class="one_third tie-columns ">
                                                        <h3  class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666 " style={{ textAlign: 'left',marginLeft:"20px"}}>Permanent Residence</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Familysponsership" class="ms-2">Family Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Spousalsponsorship" class="ms-2">Spousal Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Commonlawps" class="ms-2">Common Law Partner Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Dependantsponsorships" class="ms-2">Dependent Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Parentandgrandparentspo" class="ms-2">Parent and Grandparent Sponsorships</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Expressentry" class="ms-2">Express Entry</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Canadianexperienceclass" class="ms-2">Canadian Experience Class</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Federalskilledworkerp" class="ms-2">Federal Skilled Worker Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Federalskilledtradeprogram" class="ms-2">Federal Skilled Trade Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/provincialNprogram" class="ms-2">Provincial Nominee Programs</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Ontariolmmigrant" class="ms-2">Ontario Immigrant Nominee Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Startupvisa" class="ms-2">Start-Up Visa Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Selfemployedpersons" class="ms-2">Self Employed Persons</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Homechildcare" class="ms-2">Home Child Care Provider Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Homesupportworkerpilot" class="ms-2">Home Support Worker Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Agrifoodpilot" class="ms-2">Agri-Food Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Ruralandnorthern" class="ms-2">Rural and Northern Immigration Pilot</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Atlantic" class="ms-2">Atlantic Immigration Program</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns">
                                                        <h3 class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666" style={{ textAlign: 'left',marginLeft:"20px" }}>Temporary Residence</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Postg" class="ms-2">Post-Graduate Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LabourM" class="ms-2">Labour Market Impact Assessment (LMIA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LmiaW" class="ms-2">LMIA Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/TemporaryF" class="ms-2">Temporary Foreign Worker Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/GlobalS" class="ms-2">Global Skills Strategy</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/InterMM" class="ms-2">International Mobility Program</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/IntraC" class="ms-2">Intra Company Transfers</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/LmiaEE" class="ms-2">LMIA Exempt Work Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/FreeTrad" class="ms-2">Free Trade Agreements</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CanadaU" class="ms-2">Canada–United States–Mexico Agreement (CUSMA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/GernalAgrement" class="ms-2">General Agreement on Trade in Services (GATS)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CanadaEuropean" class="ms-2">Canada-European Union Comprehensive Economic and Trade Agreement (CETA)</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/InternationalE" class="ms-2">International Experience Canada</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/BussniessV" class="ms-2">Business Visitor Visa</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/StudyP" class="ms-2">Study Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Temporary" class="ms-2">Temporary Resident Visas</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/ParentG" class="ms-2">Parent &amp; Grandparent Super Visas</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="one_third tie-columns last">
                                                        <h3 class="wp-block-heading has-custom-gold-color has-text-color has-link-color wp-elements-2cbb7df4ee511bb404bc2ffb246a2666" style={{ textAlign: 'left',marginLeft:"20px" }}>Appeals &amp; Removals</h3>
                                                        <div class="  tie-list-shortcode">
                                                            <ul>
                                                            <li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Recon" class="ms-2">Reconsideration Requests</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Reapp" class="ms-2">Reapplications</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Author" class="ms-2">Authorization to Return</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Removal" class="ms-2">Removal &amp; Deportation</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Detention" class="ms-2">Detention Reviews</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Admiss" class="ms-2">Admissibility Hearings</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/CriminalR" class="ms-2">Criminal Rehabilitation</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Judical" class="ms-2">Judicial Review</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Immigration" class="ms-2">Immigration Appeal Division</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/ImmigrationD" class="ms-2">Immigration Division</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Criminalin" class="ms-2">Criminal Inadmissibility</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Preremoval" class="ms-2">Pre-removal Risk Assessment</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Port" class="ms-2">Port of Entry Issues</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/TemporaryResi" class="ms-2">Temporary Resident Permits</a>
</li>
<li class="d-flex list-unstyled">
    <FontAwesomeIcon className='mt-1' icon={faCheck} style={{ fontSize: '14px', color: '#81b81b' }} />&nbsp;&nbsp;
    <a href="/Humaniarian" class="ms-2">Humanitarian &amp; Compassionate Grounds</a>
</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                   
<Footer/>
        </>
    )
}
export default ImmigrationPage; 