import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
const CriminalLawPage=()=>{
    return(
        <>
 <ul id="menu-lexdefensio-nav" class="menu">
                                                    
                                                    <li id="menu-item-1121" class="menu-item menu-item-type-post_type menu-item-object-page  menu-item-1121">
<a href="">Criminal Law &nbsp;<FontAwesomeIcon icon={faAngleDown}  style={{ fontSize: '15px' }} /></a>
                                                        <ul class="sub-menu menu-sub-content">
                                                            <li id="menu-item-1179" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1179"><a href="/fraud">Fraud</a></li>
                                                            <li id="menu-item-1172" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1172"><a href="/Drugchargies">Drug Charges</a></li>
                                                            <li id="menu-item-1173" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1173"><a href="/Firearms">Firearms &#038; Weapons</a></li>
                                                            <li id="menu-item-1171" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1171"><a href="/Domesticviolence">Domestic Violence</a></li>
                                                            <li id="menu-item-1175" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1175"><a href="/Projectcases">Project Cases</a></li>
                                                            <li id="menu-item-1177" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1177"><a href="/Sexualassault">Sexual Assault</a></li>
                                                            <li id="menu-item-1180" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1180"><a href="/Whitecollarcrime">White Collar Crime</a></li>
                                                            <li id="menu-item-1181" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1181"><a href="/BailHearings">Bail Hearings</a></li>
                                                            <li id="menu-item-1178" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1178"><a href="/Duidefence">DUI Defence</a></li>
                                                            <li id="menu-item-1176" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1176"><a href="/Vehicleoffenses">Vehicle Offenses</a></li>
                                                            <li id="menu-item-1170" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1170"><a href="/Impairedd">Impaired Driving</a></li>
                                                            <li id="menu-item-1174" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1174"><a href="/Failure">Failure to Provide Breath Sample</a></li>
                                                        </ul>
                                                    </li>
                                                    </ul>

        </>
    )
}
export default CriminalLawPage; 