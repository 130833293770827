import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'



function Atlantic() {
  return (
    <div>
    <Navbar/>
                <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
               
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Atlantic Immigration Program</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p><strong>Introduction</strong></p>
                                    <p>The Atlantic Immigration Program (AIP) stands as a beacon of opportunity for skilled workers, international graduates, and employers in the Atlantic provinces of Canada. Established in 2017, this innovative initiative
                                        aims to address demographic challenges, stimulate economic growth, and enrich regional diversity. Through strategic partnerships between the federal government and Atlantic provinces (Nova Scotia, New Brunswick,
                                        Prince Edward Island, and Newfoundland and Labrador), the AIP offers a streamlined pathway to permanent residency for eligible candidates, fostering a thriving environment for talent retention and economic prosperity.</p>
                                    <p><strong>Key Components</strong></p>
                                    <ol>
                                        <li><strong>Streams and Categories</strong>:
                                            <ul>
                                                <li><strong>Atlantic International Graduate Program (AIGP)</strong>: Designed for international graduates who have completed at least a two-year program at a publicly-funded institution in one of the Atlantic
                                                    provinces. Graduates with a valid job offer in a skilled occupation may apply through this stream.</li>
                                                <li><strong>Atlantic High-Skilled Program (AHSP)</strong>: Targets skilled workers with at least one year of full-time work experience in NOC Skill Level 0, A, or B occupations in one of the Atlantic provinces.
                                                    A valid job offer is required to qualify.</li>
                                                <li><strong>Atlantic Intermediate-Skilled Program (AISP)</strong>: Intended for individuals with at least one year of full-time work experience in NOC Skill Level C occupations in one of the Atlantic provinces.
                                                    A valid job offer in a designated intermediate-skilled occupation is necessary.</li>
                                                <li><strong>Atlantic International Entrepreneur Program (AIEP)</strong>: Geared towards experienced business owners or senior managers who wish to establish or acquire a business in one of the Atlantic provinces.
                                                    Meeting specific eligibility criteria, including a minimum investment and job creation requirement, is essential.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Employer Designation</strong>:
                                            <ul>
                                                <li>Employers in the Atlantic provinces seeking to hire foreign workers must first obtain designation under the AIP. This designation facilitates the recruitment of skilled talent from overseas, contributing
                                                    to workforce diversity and addressing labor market needs.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Job Offer Requirement</strong>:
                                            <ul>
                                                <li>To qualify for the AIP, candidates must have a valid job offer from a designated employer in one of the Atlantic provinces. The job offer should be for a position that aligns with the candidate&#8217;s skill
                                                    level and meets regional labor market needs.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Language Proficiency and Educational Requirements</strong>:
                                            <ul>
                                                <li>Proficiency in English or French is a fundamental requirement for AIP applicants. Depending on the program stream, candidates may need to provide proof of language proficiency through recognized tests such
                                                    as IELTS or CELPIP for English and TEF for French. Educational credentials assessment may also be required to validate foreign qualifications.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Settlement Plan and Support</strong>:
                                            <ul>
                                                <li>Successful applicants under the AIP receive comprehensive support through settlement services provided by designated organizations in the Atlantic provinces. These services include orientation, language
                                                    training, job search assistance, and community integration support, ensuring a smooth transition and successful settlement experience.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p><strong>Benefits and Impact</strong></p>
                                    <ol>
                                        <li><strong>Regional Economic Growth</strong>:
                                            <ul>
                                                <li>The AIP plays a pivotal role in driving economic growth and development in the Atlantic provinces by attracting skilled workers, entrepreneurs, and international graduates who contribute their expertise,
                                                    innovation, and entrepreneurial spirit to local industries.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Workforce Diversity and Innovation</strong>:
                                            <ul>
                                                <li>By welcoming talent from diverse cultural backgrounds, the AIP fosters a dynamic and inclusive workforce that promotes creativity, collaboration, and innovation. This diversity enriches communities and enhances
                                                    global competitiveness.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Talent Retention and Population Growth</strong>:
                                            <ul>
                                                <li>Through pathways to permanent residency, the AIP encourages talented individuals and their families to settle in the Atlantic provinces, addressing demographic challenges, and supporting population growth.
                                                    Retaining skilled professionals strengthens key sectors and enhances the region&#8217;s long-term sustainability.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Employer Engagement and Partnerships</strong>:
                                            <ul>
                                                <li>The AIP establishes meaningful partnerships between employers, educational institutions, government agencies, and community organizations. These collaborations facilitate talent acquisition, skills development,
                                                    and knowledge exchange, driving economic success and prosperity.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p><strong>Conclusion</strong></p>
                                    <p>The Atlantic Immigration Program stands as a testament to Canada&#8217;s commitment to fostering economic growth, diversity, and innovation in the Atlantic provinces. By providing accessible pathways to permanent residency
                                        for skilled workers, entrepreneurs, and international graduates, the AIP creates opportunities for individuals to contribute their talents and expertise to vibrant communities. As a catalyst for regional development
                                        and global engagement, the AIP exemplifies Canada&#8217;s welcoming and inclusive approach to immigration, driving prosperity and success for generations to come.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
          </div>
    </div>
    </div>
   

    
   
   
    <Footer/>
    
</body>
    </div>
  )
}

export default Atlantic
