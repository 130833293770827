import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'

function ParentG() {
  return (
    <div>
    <Navbar/>
          <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
               
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Parent &#038; Grandparent Super Visas</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p>Parent and grandparent super visas in Canada offer a pathway for family reunification, enabling eligible individuals to visit and stay with their loved ones for extended periods. This unique visa category reflects Canada&#8217;s
                                        commitment to family unity while balancing immigration regulations and considerations.</p>
                                    <h3 class="wp-block-heading"><strong>Understanding Parent &amp; Grandparent Super Visas</strong></h3>
                                    <h4 class="wp-block-heading"><strong>Eligibility Criteria</strong></h4>
                                    <p>To apply for a parent or grandparent super visa, applicants must meet specific criteria. These include proving their relationship to a Canadian citizen or permanent resident who is sponsoring their visit, providing
                                        evidence of financial support, passing medical examinations, and obtaining Canadian health insurance coverage for the duration of their stay.</p>
                                    <h4 class="wp-block-heading"><strong>Visa Validity and Extensions</strong></h4>
                                    <p>Super visas are typically valid for up to ten years, allowing multiple entries into Canada during this period. Unlike standard visitor visas, which have a maximum stay of six months per entry, super visa holders can
                                        stay for up to two years per visit. This extended duration provides families with the opportunity for more meaningful and sustained visits.</p>
                                    <p>Extensions beyond the initial two-year period are possible, subject to meeting certain conditions and demonstrating ongoing ties to the home country, such as property ownership, employment, or family responsibilities.</p>
                                    <h4 class="wp-block-heading"><strong>Financial Requirements and Sponsorship</strong></h4>
                                    <p>One crucial aspect of the super visa application is the financial sponsorship. Sponsors must meet minimum income thresholds to demonstrate their ability to support the visiting parents or grandparents financially. This
                                        requirement aims to ensure that visitors do not rely on public funds during their stay in Canada.</p>
                                    <h4 class="wp-block-heading"><strong>Benefits of Super Visas</strong></h4>
                                    <p>The parent and grandparent super visa program offers several benefits:</p>
                                    <ol>
                                        <li><strong>Extended Stay:</strong> Super visa holders can stay in Canada for up to two years per visit, fostering stronger family bonds and relationships.</li>
                                        <li><strong>Multiple Entries:</strong> The visa&#8217;s multi-entry feature allows for flexibility in travel, enabling frequent visits without the need for repeated visa applications.</li>
                                        <li><strong>Healthcare Access:</strong> Super visa holders are required to obtain private Canadian health insurance, ensuring access to essential healthcare services during their stay.</li>
                                        <li><strong>Family Reunification:</strong> The program promotes family reunification by facilitating extended visits for parents and grandparents of Canadian citizens or permanent residents.</li>
                                    </ol>
                                    <h4 class="wp-block-heading"><strong>How to Apply</strong></h4>
                                    <p>Applying for a parent or grandparent super visa involves several steps:</p>
                                    <ol>
                                        <li><strong>Gather Documents:</strong> Collect all required documents, including proof of relationship, financial support, medical examinations, and health insurance coverage.</li>
                                        <li><strong>Complete Application:</strong> Fill out the application form accurately and submit it along with the supporting documents.</li>
                                        <li><strong>Biometrics:</strong> Depending on nationality, applicants may need to provide biometric data (fingerprints and photo) at a designated location.</li>
                                        <li><strong>Wait for Processing:</strong> Once submitted, applications undergo processing, which may take several weeks. It&#8217;s essential to monitor application status regularly.</li>
                                        <li><strong>Receive Visa:</strong> If approved, the applicant receives the super visa, outlining the validity period and conditions.</li>
                                    </ol>
                                    <h4 class="wp-block-heading"><strong>Conclusion</strong></h4>
                                    <p>Parent and grandparent super visas in Canada serve as a bridge for families separated by borders, allowing for meaningful and extended visits while ensuring compliance with immigration regulations. The program&#8217;s
                                        emphasis on financial sponsorship and healthcare coverage underscores Canada&#8217;s commitment to responsible immigration practices and family unity.</p>
                                    <p>For those considering sponsoring their parents or grandparents, understanding the super visa requirements and procedures is crucial for a successful and fulfilling family reunion experience in Canada.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
         
    </div>
    </div>
   

   </div>
   
    <Footer/>
    
</body>
      
    </div>
  )
}

export default ParentG
