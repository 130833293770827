import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'

function Sexualassault() {
  return (
    <>
    <Navbar/>
              <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
               
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Sexual Assault</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p><strong>Introduction</strong></p>
                                    <p>Sexual assault is a profound societal issue that profoundly impacts individuals and communities. In Canada, as in many other countries, addressing sexual assault requires a multifaceted approach that encompasses legal
                                        frameworks, support systems, education, and advocacy. This introductory literature aims to provide a comprehensive overview of sexual assault in Canada, including its prevalence, legal definitions, support services,
                                        challenges, and ongoing efforts to combat this form of violence.</p>
                                    <p><strong>Prevalence of Sexual Assault</strong></p>
                                    <p>Sexual assault is a prevalent issue in Canada, with statistics indicating the widespread nature of this crime. According to the Canadian Women&#8217;s Foundation, half of all women in Canada have experienced at least
                                        one incident of physical or sexual violence since the age of 16. Furthermore, Statistics Canada&#8217;s General Social Survey on victimization reports that women are more likely than men to experience sexual assault,
                                        with Indigenous women and girls facing significantly higher rates of victimization.</p>
                                    <p><strong>Legal Definitions and Frameworks</strong></p>
                                    <p>In Canada, sexual assault is defined and addressed within the Criminal Code. The Code defines sexual assault as any form of sexual contact without consent, encompassing a wide range of behaviors from unwanted touching
                                        to rape. The legal framework surrounding sexual assault includes provisions for consent, capacity to consent, age of consent, and various degrees of assault depending on the severity of the act and other factors.</p>
                                    <p><strong>Support Services for Survivors</strong></p>
                                    <p>Survivors of sexual assault in Canada have access to a range of support services aimed at providing physical, emotional, and legal assistance. These services include crisis hotlines, counseling and therapy, medical
                                        support, legal aid, and advocacy organizations. Organizations such as the Sexual Assault Centre of Edmonton (SACE), Assaulted Women&#8217;s Helpline, and Canadian Association of Sexual Assault Centres (CASAC) play
                                        crucial roles in supporting survivors and raising awareness about sexual violence.</p>
                                    <p><strong>Challenges and Barriers</strong></p>
                                    <p>Despite efforts to address sexual assault, numerous challenges and barriers persist. These include underreporting due to stigma and fear of retaliation, systemic barriers faced by marginalized communities, inadequacies
                                        in legal processes, and gaps in support services. Additionally, myths and misconceptions about sexual assault contribute to victim-blaming attitudes and hinder progress in prevention and support initiatives.</p>
                                    <p><strong>Ongoing Efforts and Initiatives</strong></p>
                                    <p>Canada has seen significant progress in addressing sexual assault through legislative reforms, public awareness campaigns, and advocacy efforts. Initiatives such as the #MeToo movement, bystander intervention programs,
                                        and educational initiatives in schools and universities aim to change attitudes, promote consent culture, and hold perpetrators accountable. Legal reforms, including updates to sexual assault laws and improved support
                                        for survivors in the justice system, continue to be advocated for and implemented.</p>
                                    <p><strong>Conclusion</strong></p>
                                    <p>Sexual assault remains a critical issue in Canada that requires ongoing attention, collaboration, and action from all sectors of society. By understanding the prevalence, legal frameworks, support services, challenges,
                                        and ongoing efforts, we can work towards creating a safer and more equitable society for all. Together, we can support survivors, challenge harmful attitudes, and strive for a future free from sexual violence.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
       
    </div>
    </div>
   

    </div>
    <Footer/>
    
    
</body>
    </>
  )
}

export default Sexualassault
