import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'


function Firearms() {
  return (
    <>
    <Navbar/>
              <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
               
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                            <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Temporary Foreign Worker Program</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p><strong>Introduction:</strong></p>
                                    <p>Firearms and weapons regulations in Canada are governed by stringent laws aimed at ensuring public safety while balancing the rights of individuals to possess and use such items responsibly. This introductory literature
                                        aims to provide a comprehensive overview of firearms and weapons regulations in Canada, covering key aspects such as licensing requirements, prohibited firearms, storage and transportation guidelines, and the legal
                                        framework governing their use.</p>
                                    <p><strong>Licensing Requirements:</strong></p>
                                    <p>In Canada, anyone wishing to possess or acquire a firearm must hold a valid firearms license issued by the federal government. The licensing process involves rigorous background checks, safety training, and adherence
                                        to specific eligibility criteria. The two main types of firearms licenses are Possession and Acquisition Licenses (PAL) for non-restricted firearms and Restricted Possession and Acquisition Licenses (RPAL) for restricted
                                        firearms such as handguns.</p>
                                    <p><strong>Prohibited Firearms:</strong></p>
                                    <p>Certain firearms are classified as prohibited in Canada, meaning they are not allowed for civilian possession or use except in specific authorized circumstances. Prohibited firearms include automatic firearms, converted
                                        automatic firearms, and firearms with a barrel length below specified limits. The possession, importation, or transfer of prohibited firearms is strictly regulated and requires special authorization from law enforcement
                                        authorities.</p>
                                    <p><strong>Storage and Transportation Guidelines:</strong></p>
                                    <p>To ensure firearms are stored safely and securely, Canadian law mandates specific storage requirements for firearm owners. Firearms must be stored unloaded, with ammunition stored separately and inaccessible to unauthorized
                                        individuals. Additionally, firearms must be secured with locking devices or stored in locked containers such as gun safes or cabinets.</p>
                                    <p>When transporting firearms, owners must adhere to strict guidelines to prevent unauthorized access and ensure public safety. Firearms must be unloaded and securely stored during transportation, with additional precautions
                                        for transporting restricted firearms, including proper documentation and adherence to approved transport routes.</p>
                                    <p><strong>Legal Framework and Penalties:</strong></p>
                                    <p>The legal framework governing firearms and weapons in Canada is primarily outlined in the Firearms Act and its associated regulations. Violations of firearms laws can result in severe penalties, including fines, imprisonment,
                                        and revocation of firearms licenses. It is essential for firearm owners to understand and comply with all legal requirements to avoid legal repercussions and ensure responsible firearm ownership.</p>
                                    <p><strong>Conclusion:</strong></p>
                                    <p>In conclusion, firearms and weapons regulations in Canada are designed to uphold public safety, prevent misuse, and promote responsible firearm ownership. Understanding the licensing requirements, prohibited firearms
                                        classifications, storage and transportation guidelines, and the legal framework is crucial for all firearm owners and enthusiasts. By adhering to these regulations and prioritizing safety and compliance, individuals
                                        can contribute to a safer and more secure environment while enjoying their lawful rights to possess and use firearms responsibly.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
         
    </div>
    </div>
   

   </div>
   <Footer/>
   
    
    
</body>
    </>
  )
}

export default Firearms
