import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../Navbar.js/Navbar";
import React, { useState } from "react";
import "./BussniessE.css";
import Footer from "../Footer";

const BussniessE = () => {
  const [openSliderIndex, setOpenSliderIndex] = useState(null);

  const toggleSlider = (index) => {
    setOpenSliderIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [isOnlineSelected, setIsOnlineSelected] = useState(true);

  const handleButtonClick = (isOnline) => {
    setIsOnlineSelected(isOnline);
  };

  const sliders = [
    {
      title: "Notarization Of Original Documents",
      items: [
        "Consent to travel documents",
        "Bill of Sale",
        "Property Documents",
      ],
    },
    {
      title: "Oaths and Affidavits",
      items: [
        "OSAP affidavits",
        "Affidavits of service",
        "Affidavits of divorce",
        "Affidavits of single-status",
        "Affidavits of execution – including Form 74.D",
        "Affidavit of Execution of Will or Codicil",
        "Affidavits of Identity",
        "Affidavits of the same person",
        "Affidavits for insurance claims",
        "Affidavits of divorce",
        "Any other affidavit required by law",
        "Affidavits of marital status, including separation and common law relationships",
      ],
    },
    {
      title: "Licensing and Professional Accreditation Forms",
      items: [
        "Ontario College of Physicians and Surgeons’ Applications for Certificate of Registration Authorizing Medical Practice",
        "Ontario College of Optometrists’ Applications for Certificates of Registration",
        "Law Society of Ontario Lawyer Licensing Applications and Surrender of Licence to Practice Law Application",
        "College of Registered Psychotherapists of Ontario Registration Application",
        "Travel Industry Council of Ontario Registration Application",
      ],
    },
    {
      title: "Certified True Copies of Digital Documents",
      items: [
        "Bank statements",
        "Utility bills",
        "Canada Revenue Agency notice of return",
        "Letters of employment",
        "Records of employment",
        "Letters of recommendation",
        "Electronic transcripts",
      ],
    },
    {
      title: "Service Ontario and Ministry of Transportation Forms",
      items: [
        "Application to Change an Adult’s Name",
        "Application to Change a Child’s Name",
        "Sworn Statement for a Family Gift of a Used Motor Vehicle",
        "Statement of Conscience or Religious Belief",
      ],
    },
    {
      title: "Statutory Declarations",
      items: [
        "Statutory declarations of marital status, including IMM 5409 E Statutory Declarations of Common Law Status and Statutory Declarations of Conjugal Relationships for Citizenship and Immigration Canada applications",
        "Statutory declarations for Statements in Lieu of Certificate of Non-Impediment to Marriage Abroad",
        "Statutory declarations of progress payment",
        "Statutory declarations regarding lost, stolen, or damaged passports, including Form PPTC 203E Declarations Concerning a Lost, Stolen, Inaccessible, Damaged or Found Canadian Travel Document",
        "Form PPTC 132 Statutory Declarations in Lieu of Guarantor for Passports",
        "Statutory declarations for adult name and gender change applications for Ontario",
      ],
    },
    {
      title: "Immigration, Refugee and Citizenship Canada (IRCC) Forms",
      items: [
        "IMM 0006E Application for Authorization and Statutory Declaration For Minor Child",
        "IMM 0016E Application for Authorization and Statutory Declaration",
        "IMM 5409E Statutory Declaration of Common Law Status",
        "IMM 5646E Custodianship Declaration for Minor Studying in Canada",
        "Letters of invitation",
      ],
    },
  ];

  return (
    <div style={{ backgroundColor: "white " }}>
      <Navbar />
      <body className="container mt-5" style={{ backgroundColor: "white " }}>
        <div className="text-center">
          <h1>Notary Services</h1>
        </div>
        <div style={{ fontSize: "15px" }}>
          <p>
            Integrity Law offers licensed Canadian Notary Public solutions
            through secure online video calls and in-person appointments. We aim
            to provide reliable and efficient notary services tailored to meet
            the needs of individuals, families, and businesses across Canada.
          </p>
          <p>We specialize in a wide range of notary services, including:</p>
        </div>
        <div className="container ">
          {sliders.map((slider, index) => (
            <div
              key={index}
              className={`slider-container algh-${slider.title}`}
              style={{ border: "none" }}
            >
              <div
                className={`slider-header  text-white p-3 algh-${slider.title}`}
                onClick={() => toggleSlider(index)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e8e8e8",
                  borderRadius: "4px",
                  height: "48px",
                }}
              >
                {/* icon  */}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{ fontSize: "17px", color: "#D4AA58" }}
                  />

                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#D4AA58",
                      margin: "0",
                      marginLeft: "5px",
                    }}
                    className="tayyeba"
                  >
                    {slider.title}
                  </h3>
                </div>
              </div>
              <div
                className={`slider-content tayyeba ${
                  openSliderIndex === index ? "open" : ""
                } algh-${slider.title}`}
              >
                <div className="card card-body" style={{ border: "none" }}>
                  <ul>
                    {slider.items.map((item, itemIndex) => (
                      <li
                        style={{ listStyleType: "disc", fontSize: "16px" }}
                        key={itemIndex}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
        <h2>How It Works</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className="btn"
            style={{
              backgroundColor: isOnlineSelected ? "#D4AA58" : "white",
              color: isOnlineSelected ? "white" : "#000",
            }}
            onClick={() => handleButtonClick(true)}
          >
            Online Notarization
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: !isOnlineSelected ? "#D4AA58" : "white",
              color: !isOnlineSelected ? "white" : "#000",
            }}
            onClick={() => handleButtonClick(false)}
          >
            In-Person Notarization
          </button>
        </div>
        {isOnlineSelected ? (
          <>
            <ul
              style={{ padding: "20px", paddingLeft: "50px", fontSize: "15px" }}
            >
              <li className="handleLi">
                <strong>Book an Appointment:</strong> Schedule a session at a
                time that suits you by calling us directly and we will share an
                appointment link.
              </li>
              <li className="handleLi">
                <strong>Prepare Your Documents:</strong> Have all necessary
                documents and identification ready for the video call.
              </li>
              <li className="handleLi">
                <strong>Video Call:</strong> We’ll complete the notarization
                process over a secure video link, ensuring confidentiality and
                compliance.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul
              style={{ padding: "20px", paddingLeft: "50px", fontSize: "15px" }}
            >
              <li className="handleLi">
                <strong>Visit Our Office:</strong> After scheduling, visit our
                office with your documents and ID.
              </li>
              <li className="handleLi">
                <strong>Notarization:</strong> We review and notarize your
                documents in just a few minutes.
              </li>
            </ul>
          </>
        )}
        <br />
        <br />
        <br />
        <h2
          class="wp-block-heading
                                                        "
        >
          Pricing Information
        </h2>
        <p style={{ fontSize: "15px" }}>
          Notary fees are calculated based on the number of Notary signatures,
          not the number of documents
        </p>{" "}
        <figure
          class="wp-block-table
                                                        "
        >
          <table>
            <tbody>
              <tr >
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  <strong>Number of Signatures</strong>
                </td>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  <strong>Cost per signature</strong>
                </td>
              </tr>
              <tr>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  1<sup>st</sup>
                </td>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>$70</td>
              </tr>
              <tr>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  2<sup>nd</sup> &#8211; 4<sup>th</sup>
                </td>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>$45</td>
              </tr>
              <tr>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  5<sup>th</sup> &#8211; 7<sup>th</sup>
                </td>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>$14.95</td>
              </tr>
              <tr>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>
                  8<sup>th</sup> &#8211; 12<sup>th</sup>
                </td>
                <td style={{border:"1px solid lightgray"  , padding:"11px" }}>$12</td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p
          class="has-text-align-center has-text-color has-link-color wp-elements-aa153a07ce366132b25aafe595d8c4d4 "
          style={{ color: "#d5ab5a", fontSize: "18px" }}
        >
          For more than 12 signatures contact us directly to get bulk discount
          prices!
        </p>
        <div
          style={{ height: "31px" }}
          aria-hidden="true "
          class="wp-block-spacer "
        ></div>{" "}
        <div
          class="wp-block-group has-background "
          style={{ backgroundColor: "#1f2437" }}
        >
          <div
            class="wp-block-group__inner-container is-layout-constrained
                                                        wp-block-group-is-layout-constrained "
          >
            {" "}
            <div
              style={{ height: "31px " }}
              aria-hidden="true "
              class="wp-block-spacer "
            ></div>{" "}
            <div
              class="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-2
                                                        wp-block-columns-is-layout-flex "
              style={{ fontSize: "24px " }}
            >
              {" "}
              <div
                class="wp-block-column is-vertically-aligned-center is-layout-flow wp-block-column-is-layout-flow "
                style={{ flexBasis: "66.66%" }}
              >
                {" "}
                <h2
                  class="wp-block-heading has-global-color-color has-text-color has-link-color wp-elements-1b7b6211e00f2b91754889b678473b54 "
                  style={{ fontSize: "35px" }}
                >
                  Need a document notarized?
                  <br />
                  Book an appointment
                </h2>{" "}
              </div>{" "}
              <div
                class="wp-block-column is-vertically-aligned-center is-layout-flow wp-block-column-is-layout-flow"
                style={{ flexBasis: "33.33%" }}
              >
                {" "}
                <div
                  class="wp-block-buttons has-custom-font-size has-medium-font-size is-content-justification-space-between
                                                        is-layout-flex wp-container-core-buttons-is-layout-2 wp-block-buttons-is-layout-flex "
                  style={{ fontStyle: "normal", fontWeight: "500 " }}
                >
                  {" "}
                  <div
                    class="wp-block-button has-custom-width wp-block-button__width-75
                                                        has-custom-font-size is-style-fill "
                    style={{ fontSize: "25px " }}
                  >
                    <a
                      class="wp-block-button__link has-white-color has-global-color-background-color has-text-color has-background has-link-color wp-element-button
                                                        "
                      href="tel:+1-416-562-4666 "
                      style={{ borderRadius: "15px " }}
                    >
                      Call Now
                    </a>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              style={{ height: "31px " }}
              aria-hidden="true "
              class="wp-block-spacer
                                                        "
            ></div>{" "}
          </div>
        </div>
      
      </body>
    
     <Footer/>
    
     
      
    </div>
  );
};

export default BussniessE;
