import logo from './logo.svg';
import './App.css';
import Home from './Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Fraud from './ALLpage/Fraud';
import Drugchargies from './ALLpage/Drugchargies';
import Firearms from './ALLpage/Firearms';
import BailHearings from "./ALLpage/BailHearings";
import Footer from "./Footer";





import Duidefence from './ALLpage/Duidefence';
import Failure from './ALLpage/Failure';
import Projectcases from './ALLpage/Projectcases';
import Sexualassault from './ALLpage/Sexualassault';
import Vehicleoffenses from './ALLpage/Vehicleoffenses';
import Whitecollarcrime from './ALLpage/Whitecollarcrime';
import Domesticviolence from './ALLpage/Domesticviolence';
import Impairedd from   './ALLpage/Impairedd';
import  Familysponsership from   './ALLpage/Familysponsership';
import Spousalsponsership from './ALLpage/Spousalsponsorship';
import Commonlawps from './ALLpage/Commonlawps';
import Dependentsponsorships from './ALLpage/Dependentsponsorships';
import Parentandgrandparentspo from './ALLpage/Parentandgrandparentspo';
import  Expressentry from './ALLpage/Expressentry';
import  Canadianexperienceclass from './ALLpage/Canadianexperienceclass';
import  Federalskilledtradeprogram from './ALLpage/Faderalskilledtradeprogram';
import Federalskilledworkerp from './ALLpage/Federalskilledworkerp'
import  ProvincialNprogram from './ALLpage/ProvincialNprogram';
import  Ontariolmmigrant from './ALLpage/OntarioImmigrant';
import  Startupvisa from './ALLpage/Startupvisa';
import  Selfemployedpersons from './ALLpage/Selfemployedpersons';
import   Homesupportworkerpilot from './ALLpage/Homesupportworkerpilot';
import  Homechildcare from './ALLpage/Homechildcare';
import Agrifoodpilot from './ALLpage/Agrifoodpilot';
import Ruralandnorthern from './ALLpage/Ruralandnorthern';
import Atlantic from './ALLpage/Atlantic';
import Navbar from './Navbar.js/Navbar';
import Ourteam from './Ourteam/ourteam';


import TemporaryF from './TemporaryR/TemporaryF';
import Temporary from './TemporaryR/Temporary';
import StudyP from './TemporaryR/StudyP';
import Postg from './TemporaryR/Postg';
import ParentG from './TemporaryR/ParentG';
import LmiaW from './TemporaryR/LmiaW';
import LmiaEE from './TemporaryR/LmiaEE';

import LabourM from './TemporaryR/LabourM';
import IntraC from './TemporaryR/IntraC';
import InternationalE from './TemporaryR/InternationalE';
import GlobalS from './TemporaryR/GlobalS';

import GernalAgrement from './TemporaryR/GernalAgrement';
import FreeTrade from './TemporaryR/FreeTrad';
import CanadaU from './TemporaryR/CanadaU';
import CanadaEuropean from './TemporaryR/CanadaEuropean';
import Nor from './TemporaryR/Nor';
import BussniessV from './TemporaryR/BussniessV';
import InterMM from './TemporaryR/InterMM';

import Admiss from './Approval/Admiss';
import Author from './Approval/Author';
import CriminalR from './Approval/CriminalR';
import Criminalin from './Approval/Criminalin';
import Detention from './Approval/Detention';
import Humaniarian from './Approval/Humaniarian';
import Immigration from './Approval/Immigration';
import ImmigrationD from './Approval/ImmigrationD';
import Judical from './Approval/Judical';
import Port from './Approval/Port';
import Preremoval from './Approval/Preremoval';
import Reapp from './Approval/Reapp';
import Recon from './Approval/Recon';
import Removal from './Approval/Removal';
/* The line `import Navbarr from '';` is attempting to import a component named `Navbarr` from an empty
string. This is not a valid import statement in JavaScript. */


import TemporaryResi from './Approval/TemporaryResi';


import Contactus from './Contactus/Contactus';
import ImmigrationPage from './MobileNavLinkPage/ImmigrationPage';
import CriminalLawPage from './MobileNavLinkPage/CriminalLawPage';



function App() {
  return (
   <>

    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="Navbar" element={<Navbar />} />
      


      <Route path="Fraud" element={<Fraud />} />
    
      <Route path="Firearms" element={<Firearms/>} />
      <Route path="BailHearings" element={<BailHearings/>} />
       <Route path="Drugchargies" element={<Drugchargies/>} />
      <Route path="Duidefence" element={<Duidefence/>} />
      <Route path="Failure" element={<Failure/>} />
      <Route path="Projectcases" element={<Projectcases/>} /> 
      <Route path="Sexualassault" element={<Sexualassault/>} /> 
      <Route path="Vehicleoffenses" element={<Vehicleoffenses/>} /> 
      <Route path="Whitecollarcrime" element={<Whitecollarcrime/>} /> 
      <Route path="Domesticviolence" element={<Domesticviolence/>} /> 
      <Route path="Impairedd" element={<Impairedd/>} /> 
      <Route path="Familysponsership" element={<Familysponsership/>} /> 
      <Route path="CriminalLawPage" element={<CriminalLawPage/>} /> 
      <Route path="ImmigrationPage" element={<ImmigrationPage/>} /> 
      <Route path="Contactus" element={<Contactus/>} /> 
      <Route path="Ourteam" element={<Ourteam />} />




      <Route path="Spousalsponsorship" element={<Spousalsponsership/>} /> 
      <Route path="Commonlawps" element={<Commonlawps/>} /> 

      <Route path="Dependentsponsorships" element={<Dependentsponsorships/>} /> 
      <Route path="Parentandgrandparentspo" element={<Parentandgrandparentspo/>} /> 
      <Route path="Expressentry" element={<Expressentry/>} /> 
      <Route path="Canadianexperienceclass" element={<Canadianexperienceclass/>} /> 
      <Route path="Federalskilledtradeprogram" element={<Federalskilledtradeprogram/>} /> 
      <Route path="Federalskilledworkerp" element={<Federalskilledworkerp/>} /> 

      <Route path="ProvincialNprogram" element={<ProvincialNprogram/>} /> 
      <Route path="Ontariolmmigrant" element={<Ontariolmmigrant/>} />
      <Route path="Startupvisa" element={<Startupvisa/>} /> 
      <Route path="Selfemployedpersons" element={<Selfemployedpersons/>} /> 
      <Route path="Homesupportworkerpilot" element={<Homesupportworkerpilot/>} /> 
      <Route path="Homechildcare" element={<Homechildcare/>} /> 
      <Route path="Agrifoodpilot" element={<Agrifoodpilot/>} /> 
      <Route path="Ruralandnorthern" element={<Ruralandnorthern/>} /> 
      <Route path="Atlantic" element={<Atlantic/>} /> 

      <Route path="Footer" element={<Footer/>} /> 



      <Route path="TemporaryF" element={<TemporaryF/>} /> 
      <Route path="Temporary" element={<Temporary/>} /> 
      <Route path="StudyP" element={<StudyP/>} /> 
      <Route path="Postg" element={<Postg/>} /> 
      <Route path="ParentG" element={<ParentG/>} /> 
      <Route path="LmiaW" element={<LmiaW/>} /> 
      <Route path="LmiaEE" element={<LmiaEE/>} /> 

      <Route path="LabourM" element={<LabourM/>} /> 
      <Route path="IntraC" element={<IntraC/>} /> 
      <Route path="InternationalE" element={<InternationalE/>} /> 
      <Route path="InterMM" element={<InterMM/>} /> 
      <Route path="GlobalS" element={<GlobalS/>} /> 
       
      <Route path="GernalAgrement" element={<GernalAgrement/>} /> 
      <Route path="FreeTrad" element={<FreeTrade/>} />
      <Route path="CanadaU" element={<CanadaU/>} /> 
      <Route path="CanadaEuropean" element={<CanadaEuropean/>} /> 
      <Route path="Nor" element={<Nor/>} /> 
      <Route path="BussniessV" element={<BussniessV/>} /> 


      <Route path="Admiss" element={<Admiss/>} /> 
      <Route path="Author" element={<Author/>} /> 
      <Route path="Criminalin" element={<Criminalin/>} /> 
      <Route path="CriminalR" element={<CriminalR/>} /> 

      <Route path="Detention" element={<Detention/>} /> 
      <Route path="Humaniarian" element={<Humaniarian/>} />
      <Route path="Immigration" element={<Immigration/>} /> 
      <Route path="ImmigrationD" element={<ImmigrationD/>} />
      <Route path="Judical" element={<Judical/>} /> 
      <Route path="Port" element={<Port/>} /> 
      <Route path="Preremoval" element={<Preremoval/>} /> 
      <Route path="Reapp" element={<Reapp/>} /> 
      <Route path="Recon" element={<Recon/>} /> 
      <Route path="Removal" element={<Removal/>} /> 

      <Route path="TemporaryResi" element={<TemporaryResi/>} /> 

      











      







































      














    
      
    </Routes>
  </BrowserRouter>
   </>
  );
}

export default App;
