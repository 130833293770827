import React from 'react'
import { Link } from 'react-router-dom';
import "./Navbar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import image from '../image/logooo.png'
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook } from 'react-icons/fa';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


import react, { useState, useEffect } from 'react';
function Navbar() {
  const [clicked, setClicked] = useState(false);
 

  const handleClick = () => {
console.log("clicked")

    setClicked(!clicked);
    console.log("clicked", clicked)
  };
  
//   
// 
// 
//all font icons (search, shuffle and loading) from the amazing IonIcons pack ---> http://ionicons.com/
//uses the mediawiki api
//creating the necessary cards for links



//function for finding the the wikipedia content


  return (
    <div>
       <header id="theme-header" class="theme-header header-layout-1 main-nav-dark main-nav-default-dark main-nav-below no-stream-item has-shadow has-normal-width-logo has-custom-sticky-logo mobile-header-default">
                    <div class="main-nav-wrapper  " >
                        <nav id="main-nav" class="main-nav header-nav menu-style-solid-bg" style={{ lineHeight: '184px' }} aria-label="Primary Navigation">
                            <div class="container">
                                <div class="main-menu-wrapper menu-style-solid-bg" >
                                    <div id="mobile-header-components-area_1 menu-style-solid-bg"   class="mobile-header-components">
                                        <ul class="components">
                                            <li class="mobile-component_menu custom-menu-link" style={{cursor:"pointer"}} onClick={()=>handleClick()}><span class="tie-mobile-menu-icon nav-icon is-layout-1"></span></li>
                                        </ul>
                                    </div> 




                                    <div class="header-layout-1-logo" style={{width:"300px"}}>
                                        <div id="logo" class="image-logo" style={{ marginTop: '45px', marginBottom: '10px',marginLeft:"90px" }}
>
                                            <a title="IntegrityLaw.com" href="/">
                                                <picture class="tie-logo-default tie-logo-picture">
                                                    <source class="tie-logo-source-default tie-logo-source" srcset=""/> <img class="tie-logo-img-default tie-logo-img" src="https://ik.imagekit.io/cy8phtesy/lawww-removebg-preview.png?updatedAt=1717433724152" alt="lexdefensio.com" width="500" height="79" style={{ maxHeight: '129px', width: 'auto' ,marginLeft:"0px" }}

                                                    /> </picture>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div id="mobile-header-components-area_2 menu-style-solid-bg" class="mobile-header-components">
                                        <ul class="components">
                                            <li class="mobile-component_search custom-menu-link"> <a href="#" class="tie-search-trigger-mobile"> <span class="tie-icon-search tie-search-icon" aria-hidden="true"></span> <span class="screen-reader-text">Search for</span> </a> </li>
                                        </ul>
                                    </div> */}
                                    <div id="menu-components-wrap">
                                        <div id="sticky-logo" class="image-logo">
                                            {/* <a title="Integritylaw.co" href="#">
                                                <picture class="tie-logo-default tie-logo-picture">
                                                    <source class="tie-logo-source-default tie-logo-source" srcset=""/> <img class="tie-logo-img-default tie-logo-img" src="https://ik.imagekit.io/cy8phtesy/logo2-removebg-preview.png?updatedAt=1715953717228" alt="lexdefensio.com" style={{ maxHeight: '49px', width: 'auto' }} /> </picture>
                                            </a> */}
                                        </div>
                                        <div class="flex-placeholder"></div>
                                        <div class="main-menu main-menu-wrap ">
                                            <div id="main-nav-menu" class="main-menu header-menu">
                                                <ul id="menu-lexdefensio-nav" class="menu">
                                                    <li id="menu-item-1106" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1106 menu-style-solid-bg"><a href="/">Home</a></li>
                                                    <li id="menu-item-1121" class="menu-item menu-item-type-post_type menu-item-object-page  menu-item-1121">
                                                <a href="">Criminal Law &nbsp;<FontAwesomeIcon icon={faAngleDown}  style={{ fontSize: '15px' }} /></a>
                                                        <ul class="sub-menu menu-sub-content">
                                                            <li id="menu-item-1179" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1179"><a href="/fraud">Fraud</a></li>
                                                            <li id="menu-item-1172" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1172"><a href="/Drugchargies">Drug Charges</a></li>
                                                            <li id="menu-item-1173" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1173"><a href="/Firearms">Firearms &#038; Weapons</a></li>
                                                            <li id="menu-item-1171" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1171"><a href="/Domesticviolence">Domestic Violence</a></li>
                                                            <li id="menu-item-1175" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1175"><a href="/Projectcases">Project Cases</a></li>
                                                            <li id="menu-item-1177" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1177"><a href="/Sexualassault">Sexual Assault</a></li>
                                                            <li id="menu-item-1180" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1180"><a href="/Whitecollarcrime">White Collar Crime</a></li>
                                                            <li id="menu-item-1181" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1181"><a href="/BailHearings">Bail Hearings</a></li>
                                                            <li id="menu-item-1178" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1178"><a href="/Duidefence">DUI Defence</a></li>
                                                            <li id="menu-item-1176" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1176"><a href="/Vehicleoffenses">Vehicle Offenses</a></li>
                                                            <li id="menu-item-1170" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1170"><a href="/Impairedd">Impaired Driving</a></li>
                                                            <li id="menu-item-1174" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1174"><a href="/Failure">Failure to Provide Breath Sample</a></li>
                                                        </ul>
                                                    </li>


             
             
             
             
             
                                                    <li id="menu-item-1122" class="menu-item menu-item-type-post_type menu-item-object-page  menu-item-1121">
  <a href="">
    <span aria-hidden="true" class="tie-menu-icon fab "><FontAwesomeIcon icon={faLeaf}  style={{ fontSize: '15px' }} /></span> Immigration &nbsp;<FontAwesomeIcon icon={faAngleDown}  style={{ fontSize: '15px' }} />
  </a>
  <div class="mega-menu-block menu-sub-content">
    <ul class="sub-menu-columns">
      <li id="menu-item-1186" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1186 mega-link-column">
        <a class="mega-links-head" href="#">Permanent Residence</a>
        <ul class="sub-menu-columns-item">
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Familysponsership">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Family Sponsorships
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Spousalsponsorship">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Spousal Sponsorships
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Commonlawps">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Common Law Partner Sponsorships
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Dependentsponsorships">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Dependant Sponsorships
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Parentandgrandparentspo">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Parent &#038; Grandparent Sponsorships
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Expressentry">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Express Entry
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Canadianexperienceclass">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Canadian Experience Class
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Federalskilledworkerp">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Federal Skilled Worker Program
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Faderalskilledtradeprogram">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Federal Skilled Trade Program
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/ProvincialNprogram">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Provincial Nominee Programs
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/OntarioImmigrant">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Ontario Immigrant Nominee Program
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Startupvisa">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Start-Up Visa Program
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Selfemployedpersons">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Self Employed Persons
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Homechildcare">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Home Child Care Provider Pilot
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Homesupportworkerpilot">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Home Support Worker Pilot
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Agrifoodpilot">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Agri-Food Pilot
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Ruralandnorthern">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Rural and Northern Immigration Pilot
            </a>
          </li>
          <li id="menu-item-1204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204">
            <a href="/Atlantic">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Atlantic Immigration Program
            </a>
          </li>
        


        </ul>
      </li>



      <li id="menu-item-1187" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-1187 mega-link-column">
        <a class="mega-links-head" href="/TemporaryResi">Temporary Residence</a>
        <ul class="sub-menu-columns-item">

        <li id="menu-item-1230" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1230">
  <a href="/Postg">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Post-Graduate Work Permits
  </a>
        </li>


<li id="menu-item-1231" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1231">
  <a href="/LabourM">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Labour Market Impact Assessment (LMIA)
  </a>
</li>
<li id="menu-item-1232" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1232">
  <a href="/LmiaW">
    <span aria-hidden="true" class="mega-links-default-icon"></span>LMIA Work Permits
  </a>
</li>

<li id="menu-item-1234" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1234">
  <a href="/TemporaryF">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Temporary Foreign Worker Program
  </a>
</li>

<li id="menu-item-1234" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1234">
  <a href="/GlobalS">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Global Skills Strategy
  </a>
</li>
<li id="menu-item-1235" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1235">
  <a href="/InterMM">
    <span aria-hidden="true" class="mega-links-default-icon"></span>International Mobility Program
  </a>
</li>
<li id="menu-item-1236" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1236">
  <a href="/IntraC">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Intra Company Transfers
  </a>
</li>
<li id="menu-item-1237" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1237">
  <a href="/LmiaEE">
    <span aria-hidden="true" class="mega-links-default-icon"></span>LMIA Exempt Work Permits
  </a>
</li>
<li id="menu-item-1238" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1238">
  <a href="/FreeTrad">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Free Trade Agreements
  </a>
</li>
<li id="menu-item-1292" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1292">
  <a href="/CanadaU">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Canada–United States–Mexico Agreement (CUSMA)
  </a>
</li>
<li id="menu-item-1293" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1293">
  <a href="/GernalAgrement">
    <span aria-hidden="true" class="mega-links-default-icon"></span>General Agreement on Trade in Services (GATS)
  </a>
</li>
<li id="menu-item-1294" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1294">
  <a href="/CanadaEuropean">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Canada-European Union Comprehensive Economic and Trade Agreement (CETA)
  </a>
</li>
<li id="menu-item-1295" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1295">
  <a href="/InternationalE">
    <span aria-hidden="true" class="mega-links-default-icon"></span>International Experience Canada
  </a>
</li>
<li id="menu-item-1296" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1296">
  <a href="/BussniessV">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Business Visitor Visa
  </a>
</li>
<li id="menu-item-1297" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1297">
  <a href="/StudyP">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Study Permits
  </a>
</li>
<li id="menu-item-1298" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1298">
  <a href="/Temporary">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Temporary Resident Visas
  </a>
</li>
<li id="menu-item-1299" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1299">
  <a href="/TemporaryF">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Parent & Grandparent Super Visas
  </a>
</li>

        
        
        
        </ul>
      </li>

      
      <li id="menu-item-1188" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1188 mega-link-column">
        <a class="mega-links-head" href="Removal">Appeals &#038; Removals</a>
        <ul class="sub-menu-columns-item">
          
          
          
          
        <li id="menu-item-1331" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1331">
  <a href="Recon">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Reconsideration Requests
  </a>
</li>
<li id="menu-item-1332" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1332">
  <a href="/Reapp">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Reapplications
  </a>
</li>
<li id="menu-item-1333" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1333">
  <a href="/Author">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Authorization to Return
  </a>
</li>
<li id="menu-item-1334" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1334">
  <a href="/Removal">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Removal &#038; Deportation
  </a>
</li>
<li id="menu-item-1335" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1335">
  <a href="/Detention">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Detention Reviews
  </a>
</li>
<li id="menu-item-1336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1336">
  <a href="/Admiss">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Admissibility Hearings
  </a>
</li>
<li id="menu-item-1337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1337">
  <a href="/CriminalR">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Criminal Rehabilitation
  </a>
</li>
<li id="menu-item-1338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1338">
  <a href="/Judical">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Judicial Review
  </a>
</li>
<li id="menu-item-1339" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1339">
  <a href="/Immigration">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Immigration Appeal Division
  </a>
</li>
<li id="menu-item-1340" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1340">
  <a href="/ImmigrationD">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Immigration Division
  </a>
</li>
<li id="menu-item-1341" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1341">
  <a href="/Criminalin">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Criminal Inadmissibility
  </a>
</li>
<li id="menu-item-1342" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1342">
  <a href="/CriminalR">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Pre-removal Risk Assessment
  </a>
</li>
<li id="menu-item-1343" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1343">
  <a href="/Port">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Port of Entry Issues
  </a>
</li>
<li id="menu-item-1344" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1344">
  <a href="/TemporaryResi">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Temporary Resident Permits
  </a>
</li>
<li id="menu-item-1345" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1345">
  <a href="/Humaniarian">
    <span aria-hidden="true" class="mega-links-default-icon"></span>Humanitarian &#038; Compassionate Grounds
  </a>
</li>




          <li id="menu-item-1345" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1345">
            <a href="/humanitarian-compassionate-grounds/">
              <span aria-hidden="true" class="mega-links-default-icon"></span>Humanitarian &#038; Compassionate Grounds
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</li>



                                                    <li id="menu-item-1489" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1489"><a href="/Nor">Notary Services</a></li>
                                                    <li id="menu-item-1252" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1252"><a href="/Ourteam">Our Team</a></li>
                                                    <li id="menu-item-1251" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1251"><a href="/Contactus">Contact Us</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <ul class="components">
                                            <li class="custom-menu-button menu-item custom-menu-link"> <a class="button" href="tel:+1-416-562-4666" title="Call Now"> Call Now </a> </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>





                <span style={{backgroundColor:"white"}}>

         {clicked && (
                <nav style={{ backgroundColor: '#353d5a', padding: '10px'}}>
  
        <div className="navbar-links" >
        <div class='body-containeer'>
  <div class='containeer'>
    <div class='search-containeer'>
      <input id='search-bar' type='text' placeholder='           searching' autofocus>
      </input>
      <i class='ion-close'></i>
      
    </div>
    
    
   
  </div>
</div>
<div class='cards'>
</div>
<i class='ion-load-c'/>

          <ul style={{ padding: '0' }}>
          
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle' }}>
              <a href="/" className="nav-link" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Home</a>
            </li>
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle' }}>
             
              <li id="menu-item-1121" class="menu-item menu-item-type-post_type menu-item-object-page  menu-item-1121">
<a href="/">Criminal Law &nbsp;<FontAwesomeIcon icon={faAngleDown}  style={{ fontSize: '15px',marginLeft:"800px",backgroundColor:"none" }} /></a>
                                                        {/* <ul class="sub-menu menu-sub-content">
                                                            <li id="menu-item-1179" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1179"><a href="/fraud">Fraud</a></li>
                                                            <li id="menu-item-1172" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1172"><a href="/Drugchargies">Drug Charges</a></li>
                                                            <li id="menu-item-1173" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1173"><a href="/Firearms">Firearms &#038; Weapons</a></li>
                                                            <li id="menu-item-1171" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1171"><a href="/Domesticviolence">Domestic Violence</a></li>
                                                            <li id="menu-item-1175" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1175"><a href="/Projectcases">Project Cases</a></li>
                                                            <li id="menu-item-1177" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1177"><a href="/Sexualassault">Sexual Assault</a></li>
                                                            <li id="menu-item-1180" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1180"><a href="/Whitecollarcrime">White Collar Crime</a></li>
                                                            <li id="menu-item-1181" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1181"><a href="/BailHearings">Bail Hearings</a></li>
                                                            <li id="menu-item-1178" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1178"><a href="/Duidefence">DUI Defence</a></li>
                                                            <li id="menu-item-1176" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1176"><a href="/Vehicleoffenses">Vehicle Offenses</a></li>
                                                            <li id="menu-item-1170" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1170"><a href="/Impairedd">Impaired Driving</a></li>
                                                            <li id="menu-item-1174" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1174"><a href="/Failure">Failure to Provide Breath Sample</a></li>
                                                        </ul> */}
                                                    </li>
            </li>
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle',height:"auto" }}>
              <a href="/ImmigrationPage" className="nav-link" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Immigration &nbsp;<FontAwesomeIcon icon={faAngleRight}  style={{ fontSize: '15px',marginLeft:"800px" }}/></a>
            </li>
          
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle' }}>
              <a href="/Nor" className="nav-link" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Notary Services</a>
            </li>
            
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle' }}>
              <a href="/Ourteam" className="nav-link" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Our Team</a>
            </li>
            
            <li className="nav-item" style={{ marginBottom: '10px', listStyleType: 'circle' }}>
              <a href="/Contactus" className="nav-link" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Contact Us</a>
            </li>
          </ul>
         
<center>
  

         
          <div class="social-media ">
        <p style={{color:"white",textAlign:" center"}}>Connect with us :</p>

        <div class="social-icons " style={{margin:"10px"}}>
        
          <a href="#">
          <FaFacebook style={{color:"#c29c55", fontSize:"20px" }}/>
          </a>
          <a href="#">
          <FontAwesomeIcon style={{color:"#c29c55", fontSize:"20px"}} icon={faTwitter} />
          </a>
          <a href="#">
          <FontAwesomeIcon  style={{color:"#c29c55",fontSize:"20px"}}icon={faInstagram} />
          </a>
          <a href="#">
          <FontAwesomeIcon style={{color:"#c29c55",fontSize:"20px"}} icon={faLinkedinIn} />
          </a>
         
        </div>
      
      </div>
      </center>
          
        </div>
    </nav>
      )}

                </span>
    </div>
  )
}

export default Navbar