import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'


function Familysponsership() {
  return (
  <>
  <Navbar/>
          <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
             
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Family Sponsorships</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <h4 class="wp-block-heading"><strong><br/>Family Sponsorship in Canada</strong></h4>
                                    <p>Family sponsorship is a cornerstone of Canadian immigration policy, allowing Canadian citizens and permanent residents to sponsor their family members for immigration to Canada. This process is governed by the Immigration
                                        and Refugee Protection Act (IRPA) and its accompanying regulations. Understanding the nuances of family sponsorship is crucial for both sponsors and applicants to navigate the immigration system effectively.</p>
                                    <h4 class="wp-block-heading"><strong>Who Can Sponsor?</strong></h4>
                                    <p>Canadian citizens and permanent residents who are at least 18 years old and meet specific income requirements can sponsor certain family members to come to Canada. The eligible relatives for sponsorship include spouses,
                                        common-law partners, dependent children, parents, and grandparents. Additionally, orphaned siblings, nieces, nephews, and grandchildren may be sponsored under certain circumstances.</p>
                                    <h4 class="wp-block-heading"><strong>Sponsorship Obligations</strong></h4>
                                    <p>Sponsors must meet certain financial obligations to demonstrate their ability to support the sponsored family members financially. This includes providing for the basic needs such as food, shelter, and clothing, as
                                        well as ensuring access to healthcare and other essential services.</p>
                                    <h4 class="wp-block-heading"><strong>Eligibility Criteria for Sponsored Family Members</strong></h4>
                                    <p>The eligibility criteria for sponsored family members vary depending on the relationship to the sponsor. Spouses, common-law partners, and dependent children must meet specific requirements related to their relationship
                                        status, age, and dependency. Parents and grandparents must also meet certain criteria, including financial support assurances from the sponsor.</p>
                                    <h4 class="wp-block-heading"><strong>Application Process</strong></h4>
                                    <p>The family sponsorship process involves multiple steps, including the submission of various forms and supporting documents. These documents typically include proof of the relationship between the sponsor and the sponsored
                                        family member, evidence of financial support, medical examinations, and police clearances.</p>
                                    <h4 class="wp-block-heading"><strong>Processing Times and Fees</strong></h4>
                                    <p>Processing times for family sponsorship applications can vary based on several factors, including the type of relationship and the country of origin of the sponsored family member. It’s essential to be aware of the
                                        current processing times and associated fees, which can include application fees, biometrics fees, and other administrative costs.</p>
                                    <h4 class="wp-block-heading"><strong>Appeals and Reconsideration</strong></h4>
                                    <p>In cases where a sponsorship application is refused or there are concerns raised by immigration authorities, sponsors and applicants have the right to appeal or request reconsideration. This process involves submitting
                                        additional documentation or addressing any issues raised during the initial assessment.</p>
                                    <h4 class="wp-block-heading"><strong>Legal Assistance and Resources</strong></h4>
                                    <p>Navigating the family sponsorship process can be complex, and seeking legal assistance is often advisable to ensure compliance with immigration laws and regulations. Here at Lex Defensio you will be in the company of
                                        qualified immigration lawyers who can provide guidance, review documentation, and represent sponsors and applicants in appeals or reconsideration processes.</p>
                                    <p><strong>Conclusion</strong></p>
                                    <p>Family sponsorship in Canada plays a vital role in reuniting families and fostering social cohesion. Understanding the legal framework, eligibility criteria, application process, and available resources is essential
                                        for sponsors and applicants alike. By staying informed and seeking appropriate legal assistance, individuals can navigate the complexities of family sponsorship with confidence and increase their chances of a successful
                                        immigration outcome.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
         
    </div>
    </div>
   

  
   
    
    </div>
    <Footer/>
</body>
  </>
  )
}

export default Familysponsership
