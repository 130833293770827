import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'


function Parentandgrandparentspo() {
  return (
   <>
   <Navbar/>
              <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
              
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Parent &#038; Grandparent Sponsorships</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p><strong>Introduction</strong></p>
                                    <p>Canada is renowned for its inclusive immigration policies that prioritize family reunification. One of the key pathways for family reunification is through the Parent and Grandparent Sponsorship Program. This program
                                        allows Canadian citizens and permanent residents to sponsor their parents and grandparents to immigrate to Canada as permanent residents. Understanding the nuances of this program is essential for those looking
                                        to bring their elderly family members to Canada.</p>
                                    <p><strong>Eligibility Criteria</strong></p>
                                    <p>To be eligible for sponsorship under the Parent and Grandparent Program, sponsors must meet certain criteria:</p>
                                    <ol>
                                        <li>Status: Sponsors must be Canadian citizens or permanent residents residing in Canada.</li>
                                        <li>Financial Ability: Sponsors must demonstrate their financial ability to support their sponsored parents or grandparents, ensuring they do not need social assistance from the government.</li>
                                        <li>Relationship: Sponsors must prove their relationship with the sponsored individuals, either as parents or grandparents.</li>
                                        <li>Age: Sponsored parents and grandparents must meet age requirements set by Immigration, Refugees and Citizenship Canada (IRCC).</li>
                                    </ol>
                                    <p><strong>Application Process</strong></p>
                                    <p>The application process for parent and grandparent sponsorship involves several steps:</p>
                                    <ol>
                                        <li>Interest to Sponsor Form: Sponsors must first submit an &#8220;Interest to Sponsor&#8221; form during the designated intake period, usually announced by IRCC annually.</li>
                                        <li>Invitation to Apply: Selected sponsors will receive an invitation to apply for sponsorship.</li>
                                        <li>Complete Application: Sponsors must complete the sponsorship application along with supporting documents, including proof of relationship, financial documentation, and other required forms.</li>
                                        <li>Processing Time: Once submitted, applications are processed by IRCC, and sponsors are informed of the decision.</li>
                                    </ol>
                                    <p><strong>Financial Obligations</strong></p>
                                    <p>Sponsors have financial obligations towards their sponsored parents and grandparents. These obligations include providing financial support for their basic needs, such as shelter, food, and clothing, for a specified
                                        period after their arrival in Canada. Sponsors must also sign an undertaking agreement with the government, committing to support the sponsored individuals financially.</p>
                                    <p><strong>Super Visa Option</strong></p>
                                    <p>In addition to the Parent and Grandparent Sponsorship Program, Canada offers the Super Visa option. The Super Visa allows parents and grandparents to visit their families in Canada for extended periods without the need
                                        for sponsorship. It is a multi-entry visa valid for up to 10 years, with the option to stay in Canada for up to two years per visit.</p>
                                    <p><strong>Benefits of Parent and Grandparent Sponsorship</strong></p>
                                    <p>The Parent and Grandparent Sponsorship Program offers numerous benefits:</p>
                                    <ol>
                                        <li>Family Reunification: It enables families to reunite and live together in Canada, fostering strong family ties.</li>
                                        <li>Care and Support: Sponsored parents and grandparents contribute to family care and support, particularly for children and grandchildren.</li>
                                        <li>Cultural Enrichment: Immigrant parents and grandparents bring cultural diversity and enrich the Canadian society.</li>
                                        <li>Economic Contribution: Many sponsored individuals contribute economically through their skills, knowledge, and investments.</li>
                                    </ol>
                                    <p><strong>Conclusion</strong></p>
                                    <p>Parent and grandparent sponsorship in Canada is a vital pathway for family reunification and cultural enrichment. Understanding the eligibility criteria, application process, financial obligations, and alternative options
                                        like the Super Visa is crucial for sponsors and sponsored individuals alike. By navigating the process effectively, families can enjoy the benefits of being together in Canada, creating lasting memories and contributions
                                        to the nation&#8217;s prosperity.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
         
    </div>
    </div>
   
</div>
   <Footer/>
    
    
</body>
   </>
  )
}

export default Parentandgrandparentspo
