import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook } from 'react-icons/fa';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <>

        
<div id="footer-widgets-container">
        <footer id="footer" className="site-footer dark-skin dark-widgetized-area">
          
                <div className="container">
                    <div className="footer-widget-area ">
                        <div className="tie-row">
                            <div className="tie-col-sm-6 normal-side">
                               


                                <div class="contact-info">
      <h1 class="titlee">Get in touch with us!</h1>
      <div class="info  mt-3">
      <div class="information " style={{color:"white"}}>
        <div className='mb-3'> <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;</div>
       
          <p>+1-437-733-7086<br/>+1-647-877-3673</p>
        </div>
       
     

      
      
        <div class="information "style={{color:"white"}}>
        <div className='mb-3'> <FontAwesomeIcon icon={faEnvelope} />&nbsp; &nbsp;</div>
       
          <p>info@integrity-law.ca</p>
        </div>
        <div class="information  " style={{color:"white"}} >
        <div className='mb-3'>  <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp; &nbsp;</div>
       

          <p>3040 Trafalgar Raod ,Unit 1,Oakville,Ontario L6H 7B9</p>
        </div>
       
      </div>

     
    </div>

                                <div className="container-wrapper widget widget_search">
                                    <div className="d-flex justify-content-center my-3">
                                        <div className="input-group">
                                            <input 
                                                type="search" 
                                                className="form-control" 
                                                style={{borderRadius:"3px"}}
                                                placeholder="Search &hellip;" 
                                                aria-label="Search"
                                                name="s" 
                                            />
                                            <button className="btn btn-primary" type="submit">
                                                Search
                                            </button>
                                        </div>  
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div className="tie-col-sm-6 normal-side">
                                <img src="https://ik.imagekit.io/cy8phtesy/integrity_logo-removebg-preview.png?updatedAt=1715955437178" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>
           
            
            <div id="site-info" className="site-info site-info-layout-2 ">
                <div className="container">
                    
                         <div className="copyright-text copyright-text-first">&copy; Integrity Law Professional Corporation 2024, All Rights Reserved</div>
                  
                           
                    
                </div>
           
</div>
            
           
        </footer>
        
        </div>
        </>
    );
};

export default Footer;
