import React from 'react'
import Navbar from '../Navbar.js/Navbar'
import Footer from '../Footer'
import image from '../image//Notarization.jpg'


function GlobalS() {
  return (
    <div>
    <Navbar/>
          <body id="tie-body" class="page-template-default page page-id-1215 tie-no-js block-head-4 magazine2 is-lazyload is-thumb-overlay-disabled is-desktop is-header-layout-1 sidebar-right has-sidebar post-layout-1 has-mobile-share hide_share_post_top hide_share_post_bottom">
    <div class="background-overlay">
        <div id="tie-container" class="site tie-container">
            <div id="tie-wrapper">
               
               
                <div id="content" class="site-content container">
                    <div id="main-content-row" class="tie-row main-content-row">
                        <div class="main-content tie-col-md-8 tie-col-xs-12" role="main">
                        <article id="the-post" class="container-wrapper post-content tie-standard mt-5">
                                <header class="entry-header-outer">
                                    <div class="entry-header">
                                        <h1 class="post-title entry-title">Global Skills Strategy</h1>
                                    </div>
                                </header>
                                <div class="entry-content entry clearfix">
                                    <p>As the global landscape continues to evolve, Canada remains at the forefront of attracting top talent and fostering international innovation through its Global Skills Strategy (GSS) program. Designed to facilitate the
                                        entry of highly skilled workers into Canada, the GSS program is a cornerstone of the country&#8217;s economic growth and competitiveness in today&#8217;s interconnected world.</p>
                                    <h3 class="wp-block-heading"><strong>Understanding the Global Skills Strategy Program</strong></h3>
                                    <h4 class="wp-block-heading"><strong>What is the Global Skills Strategy Program?</strong></h4>
                                    <p>The Global Skills Strategy (GSS) program is a dynamic initiative by the Canadian government aimed at addressing the growing demand for highly skilled talent in key sectors of the economy. Launched in 2017, the GSS program
                                        offers expedited work permit processing and other streamlined measures to attract top-tier talent from around the globe.</p>
                                    <h4 class="wp-block-heading"><strong>Key Objectives of the GSS Program</strong></h4>
                                    <ol>
                                        <li><strong>Attracting Top Talent:</strong> The primary goal of the GSS program is to attract and retain highly skilled individuals who can contribute to Canada&#8217;s economy and drive innovation across various sectors.</li>
                                        <li><strong>Accelerated Work Permit Processing:</strong> One of the key features of the GSS program is its expedited processing of work permits for eligible candidates. This allows businesses to onboard skilled workers
                                            quickly and efficiently.</li>
                                        <li><strong>Global Competitiveness:</strong> By attracting top global talent, the GSS program enhances Canada&#8217;s competitiveness on the international stage, fostering economic growth and innovation.</li>
                                    </ol>
                                    <h3 class="wp-block-heading"><strong>Benefits of the Global Skills Strategy Program</strong></h3>
                                    <h4 class="wp-block-heading"><strong>For Employers</strong></h4>
                                    <ul>
                                        <li><strong>Access to Top Talent:</strong> Employers can tap into a global pool of highly skilled professionals, ensuring they have the talent needed to drive their businesses forward.</li>
                                        <li><strong>Streamlined Processes:</strong> The GSS program offers simplified and expedited work permit processing, reducing administrative burdens for employers and enabling them to focus on their core operations.</li>
                                        <li><strong>Competitive Advantage:</strong> By attracting top talent, businesses gain a competitive edge in their respective industries, leading to increased productivity and innovation.</li>
                                    </ul>
                                    <h4 class="wp-block-heading"><strong>For Skilled Workers</strong></h4>
                                    <ul>
                                        <li><strong>Fast-tracked Work Permits:</strong> Skilled workers benefit from accelerated processing times for work permits, allowing them to start working in Canada sooner.</li>
                                        <li><strong>Career Growth:</strong> The GSS program provides opportunities for skilled workers to advance their careers in Canada&#8217;s dynamic and diverse work environment.</li>
                                        <li><strong>Permanent Residency Pathways:</strong> Eligible skilled workers may also have pathways to permanent residency in Canada, further enhancing their long-term prospects in the country.</li>
                                    </ul>
                                    <h3 class="wp-block-heading"><strong>Eligibility Criteria for the Global Skills Strategy Program</strong></h3>
                                    <h4 class="wp-block-heading"><strong>Employer Requirements</strong></h4>
                                    <ul>
                                        <li>Employers must be designated under the GSS program.</li>
                                        <li>Job offers must meet specific criteria related to skill level, salary, and duration.</li>
                                        <li>Employers are required to submit a Labour Market Benefits Plan outlining their commitment to skills development and job creation for Canadians.</li>
                                    </ul>
                                    <h4 class="wp-block-heading"><strong>Employee Requirements</strong></h4>
                                    <ul>
                                        <li>Skilled workers must have a job offer from a designated employer under the GSS program.</li>
                                        <li>The job offer must meet specific criteria related to salary and skill level.</li>
                                        <li>Skilled workers may be required to provide proof of qualifications and relevant work experience.</li>
                                    </ul>
                                    <h3 class="wp-block-heading"><strong>How to Apply for the Global Skills Strategy Program</strong></h3>
                                    <h4 class="wp-block-heading"><strong>Step 1: Employer Designation</strong></h4>
                                    <ul>
                                        <li>Employers must first apply for designation under the GSS program through the dedicated online portal.</li>
                                        <li>Once designated, employers can submit job offers for skilled workers under the GSS program.</li>
                                    </ul>
                                    <h4 class="wp-block-heading"><strong>Step 2: Job Offer Submission</strong></h4>
                                    <ul>
                                        <li>Employers submit job offers for skilled workers through the Employer Portal, ensuring they meet all eligibility criteria.</li>
                                        <li>Job offers are reviewed for compliance with GSS program requirements, including salary, skill level, and duration.</li>
                                    </ul>
                                    <h4 class="wp-block-heading"><strong>Step 3: Work Permit Application</strong></h4>
                                    <ul>
                                        <li>Skilled workers apply for work permits through the Global Skills Strategy work permit application process.</li>
                                        <li>Work permit applications are processed expediently, allowing skilled workers to start working in Canada as soon as possible.</li>
                                    </ul>
                                    <h3 class="wp-block-heading"><strong>Conclusion: Driving Innovation and Growth Through Global Talent</strong></h3>
                                    <p>Canada&#8217;s Global Skills Strategy program is a testament to the country&#8217;s commitment to fostering innovation, economic growth, and global competitiveness. By attracting top talent from around the world, the
                                        GSS program enriches Canada&#8217;s diverse workforce and contributes to the success of businesses across various industries. As the global economy continues to evolve, the GSS program stands as a beacon of opportunity,
                                        unlocking the potential of skilled professionals and driving prosperity for Canada and its people.</p>
                                </div>
                            </article>
                            <div class="post-components"> </div>
                        </div>
                        <aside class="sidebar tie-col-md-4 tie-col-xs-12 normal-side is-sticky" aria-label="Primary Sidebar">
                            <div class="theiaStickySidebar">
                                <link rel='stylesheet' id='tie-css-widgets-css' href="/wp-content/themes/jannah/assets/css/widgets.min.css" type='text/css' media='all' />
                                <script>
                                    console.log('Style tie-css-widgets')
                                </script>
                                <div id="media_image-2" class="container-wrapper widget widget_media_image mt-5"><a href="/Nor"><img width="500" height="750" src={image}class="image wp-image-1349 attachment-full size-full lazy-img" alt=""style={{ maxWidth: '100%', height: 'auto' }}
 decoding="async" /></a>
                                    <div
                                        class="clearfix"></div>
                            </div>
                    </div>
                    </aside>
                </div>
            </div>
        
    </div>
    </div>
   

    </div>
   
    <Footer/>
    
</body>
      
    </div>
  )
}

export default GlobalS
